import { VariantProps, cva } from "class-variance-authority";
import { cn } from "utils/utils";

const homeSectionVariants = cva("w-full max-w-full flex flex-col", {
  variants: {
    color: {
      midnightblue: "bg-midnightblue",
      turquoise: "bg-turquoise",
      white: "bg-white",
      teal: "bg-teal",
      "light-turquoise": "bg-light-turquoise",
      "extra-light-turquoise": "bg-extra-light-turquoise",
      iron: "bg-iron",
      // "light-iron": "bg-light-iron",
      transparent: "bg-transparent",
      landingPage: "bg-hero-pattern bg-cover bg-center lg:bg-cover",
      linearMidnightblue: "bg-gradient-to-b from-secondary to-secondaryDarker",
      gray100: "bg-gray-100",
      tertiary: "bg-[#D0EBEA]",
    },
    verticalPadding: {
      default: "py-6 lg:py-8 xl:py-10 xl:first:pt-0",
      clint: "py-6 lg:py-[3.75rem]",
      clintLarge: "py-10 lg:py-20",
      none: "",
    },
    topPadding: {
      default: "pt-8 lg:pt-12 xl:pt-16 xl:first:pt-0",
      desktopOnly: "lg:pt-12 xl:pt-16 xl:first:pt-0",
      none: "",
    },
    horizontalPadding: {
      default: "px-[4%] lg:px-24 xl:px-[7.875rem]",
      clint: "px-5 lg:px-10",
      desktopOnly: "px-0 lg:px-24 xl:px-[7.875rem]",
      clintDesktopOnly: "px-0 lg:px-10",
      none: "",
      clintLarge: "px-5 lg:px-[7.875rem]",
    },
  },
  defaultVariants: {
    color: "transparent",
    verticalPadding: "default",
    topPadding: "none",
    horizontalPadding: "default",
  },
});

export const HomeSection = ({
  className,
  children,
  color,
  verticalPadding,
  topPadding,
  horizontalPadding,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> &
  VariantProps<typeof homeSectionVariants>) => {
  return (
    <section
      {...props}
      className={cn(
        homeSectionVariants({
          color,
          topPadding,
          horizontalPadding,
          verticalPadding,
        }),
        className,
      )}
    >
      {children}
    </section>
  );
};
